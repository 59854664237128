<template>
  <div class="order">
    <b-row>
      <b-col class="section-title">
        <h3>Összesítés</h3>
        <hr>
      </b-col>
    </b-row>
    <div class="card order">
      <div class="card-body">
        <table class="table">
          <tbody
            v-if="getAllOrdersProducts.length > 0"
            class="body"
          >
            <tr
              v-for="item in getAllOrdersProducts"
              :key="item.product.id"
            >
              <td>{{ item.product.name }}</td>
              <td>{{ item.product.amount }} g × {{ item.product.gross_sale_price | formattedPrice }} × {{ item.qty }}</td>
              <td>{{ item.product.gross_sale_price * item.qty | formattedPrice }} </td>
              </td><td>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  @click.stop="removeProductFromOrder(item.product, item.qty)"
                >
                  Törlés
                </b-button>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <p style="text-align: center">
              Nincs hozzáadva termék!
            </p>
          </tbody>
        </table>
      </div>
      <div class="card-footer">
        <div class="final-price-details  p-2">
          Nettó: <span class="float-right"> {{ this.getSumPrice | formattedPrice }}</span>
          <hr>
          ÁFA: <span class="float-right"> {{ this.getVat | formattedPrice }}</span>
        </div>
        <div class="final-price p-2">
          Végösszeg: <span class="float-right">{{ totalPrice | formattedPrice }}</span>
        </div>
        <div class="payment">
          <b-button
            variant="success"
            :disabled="paymentDisabled"
            @click="startPayment"
          >
            FIZETÉS
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { BCol, BRow, BButton } from 'bootstrap-vue'

export default {
  name: 'Order',
  components: {
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapState(['payment']),
    ...mapGetters(['getAllOrdersProducts', 'getSumPrice', 'getVat']),
    totalPrice() {
      return this.getSumPrice + this.getVat
    },
    paymentDisabled() {
      return !this.getAllOrdersProducts.length > 0 && !this.totalPrice > 0
    },
  },
  methods: {
    async removeProductFromOrder(product, qty) {
      await this.$store.dispatch('removeProductFromOrder', product)
      await this.$store.dispatch('changeSumPrice', { type: 'remove', value: product.gross_sale_price * qty })
      await this.$store.dispatch('changeVat', { type: 'remove', value: product.vat_value * qty })
    },
    startPayment() {
      this.$router.push({ name: 'sale-checkout' })
    },
  },
}
</script>
<style lang="scss">
.order{
  .card{
   .table{
    .body{
      padding:0;
      margin:0;
      display:block;
      max-height:35vh;
      overflow-y:scroll;
      > tr{
        > td{
          margin:5px;
          padding:8px;
        }
      }
    }
   }
  }
}
</style>
