<template>
  <div>
    <b-row v-if="getWarehouseId === null">
      <b-col
        cols="10"
        class="warehouses"
      >
        <div
          v-for="warehouse in allWarehouses"
          :key="warehouse.id"
          class="warehouse"
          @click="selectWarehouse(warehouse.id)"
        >
          {{ warehouse.name }}
        </div>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col md="4">
        <div class="h-100">
          <b-row>
            <b-col cols="10">
              <Categories />
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col md="4">
        <div class="container-fluid h-100">
          <Products />
        </div>
      </b-col>
      <b-col md="4">
        <div class="container-fluid h-100">
          <Order />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import Products from '@/layouts/components/products/Products'
import Categories from '@/layouts/components/products/Categories'
import Order from '@/layouts/components/products/Order'

export default {
  components: {
    BRow,
    BCol,
    Products,
    Categories,
    Order,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      selected: null,
    }
  },
  computed: {
    ...mapGetters(['getAllItems', 'allProducts', 'getWarehouseId', 'allWarehouses']),
    ...mapState(['removeLogs', 'products', 'order', 'warehouses']),
  },
  async created() {
    await this.$store.dispatch('fetchWarehouses')

    await this.$store.dispatch('setSelectedCategory', null)
    await this.$store.dispatch('categoryLoading', false)

    await this.$store.dispatch('fetchProductsForCategory', this.getSelectedCategory ? this.getSelectedCategory.id : null)
    await this.$store.dispatch('productLoading', false)
  },

  methods: {
    selectWarehouse(id) {
      this.$store.dispatch('setWarehouseId', id)
    },
  },
}
</script>

<style lang="scss">
.repeater-form {
  overflow: visible!important;
}
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
.warehouses{
  display:flex;
  .warehouse{
    margin:10px;
    cursor:pointer;
    border-radius: 4px;
    display:flex;
    align-items: center;
    justify-content: center;
    width:220px;
    height:100px;
    background-color: rgba(0,0,0,.12);
    font-size: 1.2rem;
    font-weight: bolder;
  }
}

</style>
