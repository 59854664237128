<template>
  <div class="categories">
    <b-row>
      <b-col class="section-title">
        <h3>Kategóriák {{ this.categoryIsLoading }}</h3>
        <hr>
      </b-col>
    </b-row>
    <div v-if="!this.categoryIsLoading">
      <b-row
        v-if="hasAvailableCategories"
        style="height:calc(100vh - 280px)!important; align-content: start; overflow-x:auto"
      >
        <b-col
          v-if="getSelectedCategory"
          cols="4"
          class="category"
        >
          <div
            class="card back m-1"
            :style="{ 'background-color': '#2a9d8f' }"
            @click.prevent="setCategory(getSelectedCategory.parent)"
          >
            <div class="card-body">
              VISSZA
            </div>
          </div>
        </b-col>
        <b-col
          v-for="category in allCategories"
          :key="category.id"
          cols="4"
          class="category"
          @click.stop="setCategory(category)"
        >
          <div
            class="card m-1"
            :style="{ 'background-color': '#2a9d8f' }"
          >
            <div class="card-header">
              {{ category.name }}
            </div>
            <div class="card-body">
              <b-row>
                <b-col>
                  <img
                    class="img-fluid category-image"
                    src="images/icons/categories/fruit.png"
                    alt=""
                    srcset=""
                  ></b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row v-else>
        Nincs megjeleníthető kategória!
      </b-row>
    </div>
    <div v-else>
      Kategóriák betöltése...
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import {
  BRow,
  BCol,
} from 'bootstrap-vue'

export default {
  name: 'Categories',
  components: {
    BRow,
    BCol,
  },
  computed: {
    ...mapState(['categories', 'products']),
    ...mapGetters(['allCategories', 'getSelectedCategory', 'categoryIsLoading']),
    hasAvailableCategories() {
      return this.allCategories.length > 0
    },
  },
  methods: {
    async setCategory(category) {
      await this.$store.dispatch('categoryLoading', true)
      await this.$store.dispatch('productLoading', true)

      await this.$store.dispatch('setSelectedCategory', category)
      await this.$store.dispatch('categoryLoading', false)

      await this.$store.dispatch('fetchProductsForCategory', this.getSelectedCategory ? this.getSelectedCategory.id : null)
      await this.$store.dispatch('productLoading', false)
    },
  },
}
</script>
<style lang="scss">
.categories{
  $main-color: #2a9d8f;
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: $main-color;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .back{
    cursor:pointer;
    background-color: rgb(52, 94, 92)!important;
    display:flex;
    align-items: center;
    font-size: 1.8rem;
  }
  .category{
    display:flex;
    margint:0;
    padding:0;
    .card{
      cursor:pointer;
      padding:0;
      margin:0;
      min-height: 200px;
      min-width: 130px;
      color:white;
      .card-header{
        font-size: 1.1rem;
      }
    }
  }
}
</style>
