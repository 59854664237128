<template>
  <div class="products">
    <b-row>
      <b-col class="section-title">
        <h3>{{ (getSelectedCategory && getSelectedCategory.name) ? getSelectedCategory.name : "Fő kategóriák" }}</h3>
        <hr>
      </b-col>
    </b-row>
    <!-- Products list container -->
    <div v-if="!this.productIsLoading">
      <b-row
        v-if="hasAvailableProducts"
        style="height:calc(100vh - 280px)!important; align-content: start; overflow-x:auto"
      >
        <b-col
          v-for="product in allProducts"
          :key="product.id"
          cols="4"
          class="product"
          :class="'preview ' + product.cat"
          @click.stop="addToOrder(product)"
        >
          <div class="card m-1">
            <div class="card-header">
              {{ product.name }}
            </div>
            <div class="card-body">
              <b-row>
                <b-col>
                  <div class="quantity">
                    150g
                  </div>
                  <!--                    <div class="price">{{ product.unit_price }} Ft</div>-->
                  <div class="price">
                    {{ product.gross_sale_price | formattedPrice }}
                  </div>
                </b-col>
                <b-col>
                  <img
                    class="img-fluid product-img"
                    src="images/icons/categories/seeds.png"
                    alt=""
                    srcset=""
                  ></b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row v-else>
        Nincs megjelníthető termék!
      </b-row>
    </div>
    <div v-else>
      Termékek betöltése...
    </div>

  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { BCol, BRow } from 'bootstrap-vue'

export default {
  name: 'Products',
  components: {
    BRow,
    BCol,
  },
  computed: {
    ...mapState(['order', 'products']),
    ...mapGetters(['getSelectedCategory', 'allProducts', 'productIsLoading']),
    hasAvailableProducts() {
      return this.allProducts.length > 0
    },
  },
  methods: {
    async addToOrder(product) {
      await this.$store.dispatch('addProduct', product)
      await this.$store.dispatch('changeSumPrice', { type: 'add', value: product.gross_sale_price })
      await this.$store.dispatch('changeVat', { type: 'add', value: product.vat_value * qty  })
    },
  },

}
</script>

<style lang="scss">
.products{
  $main-color: #2a9d8f;
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: $main-color;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .product{
    display:flex;
    margint:0;
    padding:0;
    .card{
      padding:0;
      margin:0;
      min-height: 200px;
      min-width: 150px;
    }
  }
}
</style>
